/* eslint-disable no-underscore-dangle,prettier/prettier,class-methods-use-this,react/no-string-refs,react/jsx-indent,react/prop-types,prefer-template */
import React, { Component } from 'react';
import connectToStores from 'common-ui/src/alt/connectToStores';
import history from 'app-router-history';
import StepUpAuthenticationActions from '../actions/StepUpAuthenticationActions';
import StepUpAuthenticationStore from '../stores/StepUpAuthenticationStore';
import EmailInput from './EmailInput';
import TokenInput from './TokenInput';
import AuthenticatorInput from './AuthenticatorInput';
import PhoneInput from './PhoneInput';
import OtpInput from './OtpInput';
import ExpandCollapse from '../../common/components/ExpandCollapse';
import MfaDisclosure from '../../mfa/components/MfaDisclosure';
import MfaEnterCodeHeading from '../../mfa/components/MfaEnterCodeHeading';

class StepUpAuthenticationContainer extends Component {
    constructor(props) {
        super(props);

        this.stepUpAuthenticationHeaderRef = React.createRef();

        this._renderAlreadyCompleted = this._renderAlreadyCompleted.bind(this);
        this._renderServiceUnavailable = this._renderServiceUnavailable.bind(
            this,
        );
        this._renderStepUp = this._renderStepUp.bind(this);
        this._renderOtp = this._renderOtp.bind(this);
        this._renderPhoneInput = this._renderPhoneInput.bind(this);
        this._renderHelpQuestions = this._renderHelpQuestions.bind(this);
        this._renderChangeContactMethod = this._renderChangeContactMethod.bind(
            this,
        );
        this._formatPhoneNumber = this._formatPhoneNumber.bind(this);
        this._getContactInfo = this._getContactInfo.bind(this);
        this._cancelStepUp = this._cancelStepUp.bind(this);
    }

    static getStores() {
        return [StepUpAuthenticationStore];
    }

    static getPropsFromStores() {
        return StepUpAuthenticationStore.getState();
    }

    render() {
        return (
            <div id="stepUpAuthentication">
                {this.props.error === 'alreadyCompleted' ||
                this.props.stepUpStatus === 'AUTH_10109'
                    ? this._renderAlreadyCompleted()
                    : this.props.error === 'serviceUnavailable'
                    ? this._renderServiceUnavailable()
                    : this.props.showOtp
                    ? this._renderOtp()
                    : this._renderStepUp()}
            </div>
        );
    }

    componentDidMount() {
        StepUpAuthenticationActions.setCSRFToken(this.props.xt);

        if (this.props.isMobileResponse) {
            StepUpAuthenticationActions.setIsMobile(this.props.actionId);
        } else {
            StepUpAuthenticationActions.setRedirectUrl(
                this.props.authenticatedUrl,
            );
        }

        if (this.props.successCallback) {
            StepUpAuthenticationActions.setSuccessHandler(
                this.props.successCallback,
            );
        }

        if (this.props.forAppCode) {
            StepUpAuthenticationActions.setAppCode(this.props.forAppCode);
        }

        if (this.props.feature) {
            StepUpAuthenticationActions.setFeatureCode(this.props.feature);
        }

        if (this.props.repeatableChallenge) {
            StepUpAuthenticationActions.setIsRepeatableChallenge(
                this.props.repeatableChallenge,
            );
        }

        if (this.props.reason) {
            StepUpAuthenticationActions.setReasonForStepUp(this.props.reason);
        }

        setTimeout(() => {
            if (
                this.stepUpAuthenticationHeaderRef &&
                this.stepUpAuthenticationHeaderRef.current
            ) {
                this.stepUpAuthenticationHeaderRef.current.focus();
            }
        }, 300);
    }

    componentWillUnmount() {
        console.log(
            '[StepUpAuthenticationContainer]removing the adJourneyId and sessionId from the session STorage:',
            sessionStorage,
            'windowStorage:',
            window.top.sessionStorage,
        );
        sessionStorage.removeItem('adJourneyId');
        sessionStorage.removeItem('sessionId');
    }

    _renderAlreadyCompleted() {
        return (
            <div id="stepUpAlreadyCompletedPage" className="fade-in">
                <div
                    id="stepUpAlreadyCompletedContainer"
                    className="container step-up-container"
                >
                    <div
                        id="stepUpAlreadyCompletedMessage"
                        className={
                            this.props.successCallback ? '' : 'di-container'
                        }
                    >
                        <div
                            id="stepUpAuthenticationHeader"
                            className="page-header"
                            ref={this.stepUpAuthenticationHeaderRef}
                            tabIndex="-1"
                        >
                            <h1>
                                {
                                    this.props.i18nStrings
                                        .step_up_authentication_header
                                }
                            </h1>
                        </div>
                        <div
                            id="stepUpAlreadyCompletedDescription"
                            className="header-description"
                        >
                            {
                                this.props.i18nStrings
                                    .step_up_authentication_completed
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderServiceUnavailable() {
        return (
            <div id="stepUpServiceUnavailablePage" className="fade-in">
                <div
                    id="stepUpServiceUnavailableContainer"
                    className="container step-up-container"
                >
                    <div
                        id="stepUpServiceUnavailableMessage"
                        className={
                            this.props.successCallback ? '' : 'di-container'
                        }
                    >
                        <div
                            id="stepUpAuthenticationHeader"
                            className="page-header"
                            ref={this.stepUpAuthenticationHeaderRef}
                            tabIndex="-1"
                        >
                            <h1>
                                {
                                    this.props.i18nStrings
                                        .step_up_authentication_header
                                }
                            </h1>
                        </div>
                        <div id="stepUpServiceUnavailableDescription">
                            {
                                this.props.i18nStrings
                                    .step_up_authentication_unavailable
                            }
                            {this.props.errorNumber !== '' &&
                            this.props.errorNumber !== undefined ? (
                                <span>&nbsp;({this.props.errorNumber})</span>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderStepUp() {
        return (
            <div id="stepUpAuthenticationPage" className="fade-in">
                <div
                    id="stepUpContainer"
                    className="container step-up-container"
                >
                    <div
                        id="stepUpAuthenticationForm"
                        className={
                            this.props.successCallback ? '' : 'di-container'
                        }
                    >
                        <div
                            id="stepUpAuthenticationHeader"
                            className="page-header"
                            ref={this.stepUpAuthenticationHeaderRef}
                            tabIndex="-1"
                        >
                            <h1>
                                {
                                    this.props.i18nStrings
                                        .step_up_authentication_header
                                }
                            </h1>
                        </div>
                        <div id="stepUpDescription">
                            {this.props.showMismatch ? (
                                <div>
                                    <h2
                                        id="stepUpMismatchDescription"
                                        className="header-description"
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.i18nStrings
                                                .step_up_authentication_mismatch,
                                        }}
                                    />
                                    {this.props.showPhone &&
                                    !this.props.showEmail &&
                                    !this.props.showAuthenticator &&
                                    !this.props.showToken ? (
                                        <div
                                            id="stepUpVerify"
                                            className="header-description"
                                        >
                                            {
                                                this.props.securityOptions.phone
                                                    .warning
                                            }
                                        </div>
                                    ) : !this.props.showPhone &&
                                      this.props.showEmail &&
                                      !this.props.showAuthenticator &&
                                      !this.props.showToken ? (
                                        <div
                                            id="stepUpVerify"
                                            className="header-description"
                                        >
                                            {
                                                this.props.securityOptions.email
                                                    .warning
                                            }
                                        </div>
                                    ) : !this.props.showPhone &&
                                      !this.props.showEmail &&
                                      (this.props.showAuthenticator ||
                                          this.props.showToken) ? (
                                        <div
                                            id="stepUpVerify"
                                            className="header-description"
                                        >
                                            {
                                                this.props.securityOptions.otp
                                                    .error
                                            }
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div
                                    id="stepUpAuthenticationDescription"
                                    className="header-description"
                                >
                                    {
                                        this.props.i18nStrings
                                            .step_up_authentication_description
                                    }
                                </div>
                            )}
                        </div>
                        <div
                            id="stepUpAuthenticationInput"
                            className="main-container"
                        >
                            {!this.props.showMismatch ? (
                                <div>
                                    {this.props.i18nStrings
                                        .step_up_authentication_choose !==
                                    undefined ? (
                                        <div
                                            id="stepUpAuthenticationChoose"
                                            className="row"
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.i18nStrings
                                                    .step_up_authentication_choose,
                                            }}
                                        />
                                    ) : null}
                                    <div
                                        id="stepUpAuthenticationOption"
                                        className="row"
                                    >
                                        {
                                            this.props.i18nStrings
                                                .step_up_authentication_option
                                        }
                                    </div>
                                </div>
                            ) : null}
                            {(this.props.securityOptions.phone.isVoiceEnabled ||
                                this.props.securityOptions.phone
                                    .isSmsEnabled) &&
                            this.props.showPhone
                                ? this._renderPhoneInput()
                                : null}
                            {this.props.securityOptions.email !== undefined &&
                            this.props.showEmail ? (
                                <div id="emailInputForm" className="form-group">
                                    {this.props.showMultipleOptions &&
                                    (this.props.securityOptions.phone
                                        .isVoiceEnabled ||
                                        this.props.securityOptions.phone
                                            .isSmsEnabled) ? (
                                        <div
                                            id="stepUpAuthenticationEmailOr"
                                            className="stepUpAuthenticationOr"
                                        >
                                            {
                                                this.props.i18nStrings
                                                    .step_up_authentication_or
                                            }
                                        </div>
                                    ) : null}
                                    <EmailInput
                                        securityOptionEmail={
                                            this.props.securityOptions.email
                                        }
                                        loaderText={
                                            this.props.i18nStrings.loaderText
                                        }
                                    />
                                </div>
                            ) : null}
                            {this.props.securityOptions.token !== undefined &&
                            this.props.showToken ? (
                                <div id="tokenInputForm" className="form-group">
                                    {this.props.showMultipleOptions &&
                                    (this.props.securityOptions.phone
                                        .isVoiceEnabled ||
                                        this.props.securityOptions.phone
                                            .isSmsEnabled ||
                                        this.props.securityOptions.email !==
                                            undefined) ? (
                                        <div
                                            id="stepUpAuthenticationTokenOr"
                                            className="stepUpAuthenticationOr"
                                        >
                                            {
                                                this.props.i18nStrings
                                                    .step_up_authentication_or
                                            }
                                        </div>
                                    ) : null}
                                    <TokenInput
                                        securityOptionToken={
                                            this.props.securityOptions.token
                                        }
                                        loaderText={
                                            this.props.i18nStrings.loaderText
                                        }
                                    />
                                </div>
                            ) : null}
                            {this.props.securityOptions.authenticator !==
                                undefined && this.props.showAuthenticator ? (
                                <div
                                    id="authenticatorInputForm"
                                    className="form-group"
                                >
                                    {this.props.showMultipleOptions &&
                                    (this.props.securityOptions.phone
                                        .isVoiceEnabled ||
                                        this.props.securityOptions.phone
                                            .isSmsEnabled ||
                                        this.props.securityOptions.email !==
                                            undefined ||
                                        this.props.securityOptions.token !==
                                            undefined) ? (
                                        <div
                                            id="stepUpAuthenticationAuthenticatorOr"
                                            className="stepUpAuthenticationOr"
                                        >
                                            {
                                                this.props.i18nStrings
                                                    .step_up_authentication_or
                                            }
                                        </div>
                                    ) : null}
                                    <AuthenticatorInput
                                        securityOptionAuthenticator={
                                            this.props.securityOptions
                                                .authenticator
                                        }
                                        loaderText={
                                            this.props.i18nStrings.loaderText
                                        }
                                    />
                                </div>
                            ) : null}
                            {this.props.isMobileFlow ? null : (
                                <div
                                    id="cancelButtonContainer"
                                    className="form-group"
                                >
                                    <div
                                        id="cancelButtonStepUp"
                                        className="row"
                                    >
                                        <span
                                            id="cancelButtonHome"
                                            className="row col-sm-6 col-xs-12 button-container"
                                        >
                                            <button
                                                type="button"
                                                id="cancelButton"
                                                className="btn btn-primary"
                                                disabled={false}
                                                onClick={
                                                    this.props
                                                        .cancelCallback !==
                                                    undefined
                                                        ? this.props
                                                              .cancelCallback
                                                        : this._cancelStepUp
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: this.props
                                                        .i18nStrings.cancel,
                                                }}
                                            />
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            {this.props.showChangeContact
                                ? this._renderChangeContactMethod()
                                : this._renderHelpQuestions()}
                        </div>
                    </div>
                </div>
                {this.props.showPhone &&
                this.props.securityOptions.phone.isSmsEnabled ? (
                    <div
                        id="stepUpAuthenticationDisclosureContainer"
                        className="container step-up-container"
                    >
                        <div
                            id="stepUpAuthenticationDisclosureDI"
                            className={
                                this.props.successCallback ? '' : 'di-container'
                            }
                        >
                            <div
                                id="stepUpAuthenticationDisclosure"
                                className="main-container"
                            >
                                <MfaDisclosure
                                    disclosure={this.props.dataRates.disclosure}
                                    title={this.props.dataRates.title}
                                    expandedTitle={
                                        this.props.dataRates.expandedTitle
                                    }
                                    link={this.props.dataRates.link}
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    _renderPhoneInput() {
        return (
            <div id="phoneInputForm" className="form-group">
                <PhoneInput
                    securityOptionPhone={this.props.securityOptions.phone}
                    i18nStrings={this.props.i18nStrings}
                />
            </div>
        );
    }

    _renderOtp() {
        return (
            <div id="stepUpAuthenticationOtpPage" className="fade-in">
                <div
                    id="stepUpAuthenticationOtpContainer"
                    className="container step-up-container"
                >
                    <div
                        id="stepUpAuthenticationOtpForm"
                        className={
                            this.props.successCallback ? '' : 'di-container'
                        }
                    >
                        <div
                            id="stepUpAuthenticationHeader"
                            className="page-header"
                            ref={this.stepUpAuthenticationHeaderRef}
                            tabIndex="-1"
                        >
                            <h1>
                                {
                                    this.props.i18nStrings
                                        .step_up_authentication_header
                                }
                            </h1>
                        </div>
                        <MfaEnterCodeHeading
                            config={this.props.securityOptions.otp}
                            contactInfo={this._getContactInfo()}
                            isMobileFlow={this.props.isMobileFlow}
                        />
                        <div className="main-container">
                            <OtpInput
                                securityOptionOtp={
                                    this.props.securityOptions.otp
                                }
                                showDifferentMethodLink={
                                    this.props.cancelCallback === undefined
                                }
                                forAppCode={this.props.forAppCode}
                                actionId={this.props.actionId}
                                reason={this.props.reason}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderHelpQuestions() {
        return (
            <div id="stepUpAuthenticationHelp" className="margin-top-10">
                <div id="stepUpAuthenticationHelpTitle">
                    {this.props.i18nStrings.step_up_authentication_help}
                </div>
                <ExpandCollapse
                    id="stepUpAuthenticationHelpAdmin"
                    title={
                        this.props.i18nStrings.step_up_authentication_no_answer
                    }
                    expandedTitle={
                        this.props.i18nStrings.step_up_authentication_no_answer
                    }
                >
                    <div
                        className="step-up-authentication-dropdown-content"
                        id="stepUpAuthenticationHelpNoPhone"
                    >
                        <div
                            id="stepUpAuthenticationNoPhone"
                            dangerouslySetInnerHTML={{
                                __html: this.props.i18nStrings
                                    .step_up_authentication_no_phone,
                            }}
                        />
                    </div>
                </ExpandCollapse>
            </div>
        );
    }

    _renderChangeContactMethod() {
        return (
            <div>
                {this.props.i18nStrings.step_up_authentication_change !==
                undefined ? (
                    <div
                        id="changeContactMethodContainer"
                        className="margin-top-10"
                    >
                        <div
                            className="step-up-link brand-link"
                            id="changeContactMethodLink"
                            onClick={
                                StepUpAuthenticationActions.changeContactLinkClicked
                            }
                        >
                            {
                                this.props.i18nStrings
                                    .step_up_authentication_change
                            }
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    _getContactInfo() {
        const contactInfo = {};
        contactInfo.protocol = this.props.destinationProtocol;

        if (contactInfo.protocol === 'EMAIL') {
            contactInfo.contactInfo = this.props.email;
        } else {
            contactInfo.contactInfo = this._formatPhoneNumber(
                this.props.phoneCountry,
                this.props.phoneNumber,
            );
        }

        return contactInfo;
    }

    _formatPhoneNumber(countryCode, phoneNumber) {
        if (phoneNumber === null) {
            return '';
        }

        let phoneNumberStripped = phoneNumber.replace(/[^0-9]/g, '');

        if (countryCode === '1' && phoneNumberStripped.length === 10) {
            return (
                '(' +
                phoneNumberStripped.substring(0, 3) +
                ') ' +
                phoneNumberStripped.substring(3, 6) +
                '-' +
                phoneNumberStripped.substring(6, 10)
            );
        }
        phoneNumberStripped = '+' + countryCode + ' ' + phoneNumberStripped;

        return phoneNumberStripped;
    }

    _cancelStepUp() {
        history().replace('/app/home');
    }
}

export default connectToStores(StepUpAuthenticationContainer);
