/* eslint-disable prefer-template */
import AltInstance from 'common-ui/src/alt/AltInstance';
import history from 'app-router-history';

import StepUpAuthenticationActions from '../actions/StepUpAuthenticationActions';
import StepUpAuthenticationService from '../services/StepUpAuthenticationService';
import Constants from '../../common/constants/Constants';

class StepUpAuthenticationStore {
    constructor() {
        this.phoneCountry = '1';
        this.phoneNumber = '';
        this.noPhoneSubmitted = false;
        this.validPhone = true;
        this.submittingPhone = false;
        this.email = '';
        this.validEmail = true;
        this.submittingEmail = false;
        this.token = '';
        this.validToken = true;
        this.submittingToken = false;
        this.authenticator = '';
        this.validAuthenticator = true;
        this.submittingAuthenticator = false;
        this.otp = '';
        this.validOtp = true;
        this.submittingOtp = false;
        this.destinationProtocol = '';
        this.CSRFToken = '';
        this.isMobile = false;
        this.error = '';
        this.errorNumber = '';
        this.linkAsShow = true;
        this.redirectUrl = '';
        this.showAuthenticator = true;
        this.showEmail = true;
        this.showOtp = false;
        this.showPhone = true;
        this.showToken = true;
        this.showMismatch = false;
        this.showMultipleOptions = true;
        this.showChangeContact = false;
        this.successHandler = null;
        this.appCode = '';
        this.featureCode = '';
        this.isRepeatableChallenge = false;
        this.reasonForStepUp = '';

        this.bindActions(StepUpAuthenticationActions);
        this.submitPhone = this.submitPhone.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
        this.setStepUpAuthenticationError = this.setStepUpAuthenticationError.bind(
            this,
        );
    }

    onToggleLinkAsShow() {
        this.linkAsShow = !this.linkAsShow;
    }

    onSetSuccessHandler(successHandler) {
        this.successHandler = successHandler;
    }

    onSetAppCode(appCode) {
        this.appCode = appCode;
    }

    onSetFeatureCode(featureCode) {
        this.featureCode = featureCode;
    }

    onSetIsRepeatableChallenge(isRepeatableChallenge) {
        this.isRepeatableChallenge = isRepeatableChallenge;
    }

    onSetReasonForStepUp(reason) {
        this.reasonForStepUp = reason;
    }

    onSetPhoneCountry(phoneCountryValue) {
        this.phoneCountry = phoneCountryValue;
    }

    onSetPhoneNumber(phoneNumberValue) {
        this.phoneNumber = phoneNumberValue;
    }

    onSetNoPhoneSubmitted() {
        this.noPhoneSubmitted = false;
    }

    onSetValidPhone(valid) {
        this.validPhone = valid;
    }

    onSubmitPhoneCall() {
        if (this.phoneNumber === '') {
            this.validPhone = false;
            this.noPhoneSubmitted = true;
        }

        if (this.validPhone) {
            this.destinationProtocol = 'VOICE';
            this.submitPhone();
        }
    }

    onSubmitPhoneText() {
        if (this.phoneNumber === '') {
            this.validPhone = false;
            this.noPhoneSubmitted = true;
        }

        if (this.validPhone) {
            this.destinationProtocol = 'SMS';
            this.submitPhone();
        }
    }

    submitPhone() {
        this.submittingPhone = true;
        StepUpAuthenticationService.sendStepUpAuthenticationPhone(
            this.destinationProtocol,
            this.phoneCountry,
            this.phoneNumber,
            this.CSRFToken,
            this.appCode,
            this.featureCode,
            this.isRepeatableChallenge,
            this.reasonForStepUp,
        )
            .then((response) => {
                this.submittingPhone = false;

                const successfulResponse = this.handleResponse(response);

                if (successfulResponse === 'true') {
                    StepUpAuthenticationActions.setShowOtp(true);
                } else if (successfulResponse === 'false') {
                    StepUpAuthenticationActions.setMismatch();
                }
            })
            .catch(() => {
                this.submittingPhone = false;

                StepUpAuthenticationActions.setMismatch();
            });
    }

    onSetEmail(emailValue) {
        this.email = emailValue;
    }

    onSetValidEmail(valid) {
        this.validEmail = valid;
    }

    onSubmitEmail() {
        if (this.email === '') {
            this.validEmail = false;
        }

        if (this.validEmail) {
            this.destinationProtocol = 'EMAIL';
            this.submittingEmail = true;
            StepUpAuthenticationService.sendStepUpAuthenticationEmail(
                this.email,
                this.CSRFToken,
                this.appCode,
                this.featureCode,
                this.isRepeatableChallenge,
                this.reasonForStepUp,
            )
                .then((response) => {
                    this.submittingEmail = false;

                    const successfulResponse = this.handleResponse(response);

                    if (successfulResponse === 'true') {
                        StepUpAuthenticationActions.setShowOtp(true);
                    } else if (successfulResponse === 'false') {
                        StepUpAuthenticationActions.setMismatch();
                    }
                })
                .catch(() => {
                    this.submittingEmail = false;

                    StepUpAuthenticationActions.setMismatch();
                });
        }
    }

    onSetToken(tokenValue) {
        this.token = tokenValue;
    }

    onSetValidToken(valid) {
        this.validToken = valid;
    }

    onSubmitToken() {
        if (this.token === '') {
            this.validToken = false;
        }

        if (this.validToken) {
            this.destinationProtocol = 'TOKEN';
            this.submittingToken = true;
            StepUpAuthenticationService.sendStepUpAuthentication(
                this.destinationProtocol,
                this.token,
                this.CSRFToken,
                this.appCode,
                this.featureCode,
                this.isRepeatableChallenge,
                this.reasonForStepUp,
            )
                .then((response) => {
                    this.submittingToken = false;

                    if (this.handleResponse(response) === 'false') {
                        StepUpAuthenticationActions.setMismatch();
                    }
                })
                .catch(() => {
                    this.submittingToken = false;

                    StepUpAuthenticationActions.setMismatch();
                });
        }
    }

    onSetAuthenticator(authenticatorValue) {
        this.authenticator = authenticatorValue;
    }

    onSetValidAuthenticator(valid) {
        this.validAuthenticator = valid;
    }

    onSubmitAuthenticator() {
        if (this.authenticator === '') {
            this.validAuthenticator = false;
        }

        if (this.validAuthenticator) {
            this.destinationProtocol = 'TOTP';
            this.submittingAuthenticator = true;
            StepUpAuthenticationService.sendStepUpAuthentication(
                this.destinationProtocol,
                this.authenticator,
                this.CSRFToken,
                this.appCode,
                this.featureCode,
                this.isRepeatableChallenge,
                this.reasonForStepUp,
            )
                .then((response) => {
                    this.submittingAuthenticator = false;

                    if (this.handleResponse(response) === 'false') {
                        StepUpAuthenticationActions.setMismatch();
                    }
                })
                .catch(() => {
                    this.submittingAuthenticator = false;

                    StepUpAuthenticationActions.setMismatch();
                });
        }
    }

    onSetOtp(otpValue) {
        this.otp = otpValue;
    }

    onSetValidOtp(valid) {
        this.validOtp = valid;
    }

    onSubmitOtp() {
        if (this.otp === '') {
            this.validOtp = false;
        } else {
            this.submittingOtp = true;
            StepUpAuthenticationService.sendStepUpAuthentication(
                this.destinationProtocol,
                this.otp,
                this.CSRFToken,
                this.appCode,
                this.featureCode,
                this.isRepeatableChallenge,
                this.reasonForStepUp,
            )
                .then((response) => {
                    if (this.handleResponse(response) === 'false') {
                        this.submittingOtp = false;
                        StepUpAuthenticationActions.setValidOtp(false);
                    } else {
                        sessionStorage.removeItem('sessionId');
                    }
                })
                .catch(() => {
                    this.submittingOtp = false;

                    StepUpAuthenticationActions.setValidOtp(false);
                });
        }
    }

    onChangeContactLinkClicked() {
        this.error = '';
        this.showAuthenticator = true;
        this.showEmail = true;
        this.showPhone = true;
        this.showToken = true;
        this.showMultipleOptions = true;
        this.showChangeContact = false;
    }

    onSetMismatch() {
        let authenticator = false;
        let email = false;
        let phone = false;
        let token = false;

        switch (this.destinationProtocol) {
            case 'VOICE':
            case 'SMS':
                phone = true;
                break;
            case 'EMAIL':
                email = true;
                break;
            case 'TOTP':
                authenticator = true;
                break;
            case 'TOKEN':
                token = true;
                break;
            default:
                break;
        }

        this.showMismatch = true;
        this.showAuthenticator = authenticator;
        this.showEmail = email;
        this.showPhone = phone;
        this.showToken = token;
        this.showMultipleOptions = false;
        this.showChangeContact = true;
    }

    onSetShowOtp(display) {
        this.showOtp = display;
    }

    onSetRedirectUrl(redirectUrl) {
        this.redirectUrl = redirectUrl;
    }

    onSetCSRFToken(CSRFToken) {
        this.CSRFToken = CSRFToken;
    }

    // Indicates the response is for cma
    onSetIsMobile(actionId) {
        this.isMobile = true;

        if (actionId != null && actionId !== '') {
            this.redirectUrl =
                Constants.MOBILE_CALLBACK_SUCCESS_URL + '&actionId=' + actionId;
        } else {
            this.redirectUrl = Constants.MOBILE_CALLBACK_SUCCESS_URL;
        }
    }

    handleResponse(response) {
        let success = 'false';

        if (response.data == null) {
            return success;
        }

        if (response.data.success === 'success') {
            success = 'true';
        } else if (response.data.success === 'redirect') {
            if (this.successHandler) {
                this.successHandler();
                success = 'true';
            } else {
                success = this.submitContinue();
            }
        } else {
            success = this.setStepUpAuthenticationError(
                response.data.errorCode,
                response.data.errorNumber,
            );
        }

        return success;
    }

    submitContinue() {
        let success = 'true';
        if (
            this.redirectUrl !== '' &&
            this.redirectUrl !== undefined &&
            this.redirectUrl !== null
        ) {
            if (this.redirectUrl.indexOf('/app') === 0) {
                history().replace(this.redirectUrl);
            } else {
                let url;

                if (
                    this.redirectUrl.indexOf('http') === 0 ||
                    this.redirectUrl.indexOf('cma') === 0 ||
                    this.redirectUrl.indexOf('/') === 0
                ) {
                    url = this.redirectUrl;
                } else {
                    url = window.location.protocol + '//' + this.redirectUrl;
                }
                window.location.replace(url);
            }
        } else {
            success = this.setStepUpAuthenticationError(
                'serviceUnavailable',
                '75401',
            );
        }

        return success;
    }

    setStepUpAuthenticationError(errorCode, errorNumber) {
        let success = 'redirect';

        if (errorCode == null || errorCode === '') {
            success = 'false';
        } else if (
            errorCode === 'lockout' ||
            errorCode === 'serviceStopped' ||
            errorCode === 'userHold'
        ) {
            if (this.isMobile) {
                if (errorCode === 'lockout') {
                    window.location.replace(
                        Constants.MOBILE_CALLBACK_LOCKOUT_URL,
                    );
                } else if (errorCode === 'serviceStopped') {
                    window.location.replace(
                        Constants.MOBILE_CALLBACK_STOPPED_URL,
                    );
                } else if (errorCode === 'userHold') {
                    window.location.replace(
                        Constants.MOBILE_CALLBACK_USER_HOLD_URL,
                    );
                } else {
                    window.location.replace(
                        Constants.MOBILE_CALLBACK_FAILURE_URL,
                    );
                }
            } else {
                if (window.parent.location.pathname.indexOf('/live') !== -1) {
                    window.parent.location.href =
                        '/dbank/live/app/logout?reason=' + errorCode;
                } else if (
                    window.parent.location.pathname.indexOf('/beta/') !== -1
                ) {
                    window.parent.location.href =
                        '/dbank/beta/app/logout?reason=' + errorCode;
                } else if (window.location.pathname.indexOf('/olb') === -1) {
                    window.location.href = '../logout?reason=' + errorCode;
                } else {
                    window.location.href = '../../logout?reason=' + errorCode;
                }
            }
        } else if (errorCode === 'serviceUnavailable') {
            success = 'false';
            this.error = errorCode;

            if (errorNumber != null && errorNumber !== '') {
                this.errorNumber = errorNumber;
            }
        } else {
            success = 'false';
            this.error = errorCode;
        }

        return success;
    }
}

export default AltInstance.createStore(
    StepUpAuthenticationStore,
    'StepUpAuthenticationStore',
);
