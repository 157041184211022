/* eslint-disable strict,lines-around-directive,import/order,no-unused-vars,no-var,no-underscore-dangle,react/sort-comp,eqeqeq,padding-line-between-statements,react/destructuring-assignment,react/prop-types,vars-on-top,no-param-reassign,prefer-template,no-unneeded-ternary,prefer-const,react/no-string-refs,no-else-return,class-methods-use-this,no-self-assign,prefer-destructuring,no-multi-assign,func-names,react/static-property-placement,react/boolean-prop-naming */
'use strict';
import FastClick from 'fastclick';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import styles from './IFrame.scss';

/* global DI:false, iFrameResize:false */

var DEFAULT_UNCONTROLLED_IFRAME_HEIGHT = 600;
export default class IFrame extends Component {
    constructor(props) {
        super(props);

        this.controlledRef = React.createRef();
        this.uncontrolledRef = React.createRef();

        // bind this component to the following custom functions.
        this._renderFrameContents = this._renderFrameContents.bind(this);
        this._injectJS = this._injectJS.bind(this);
        this._injectCSS = this._injectCSS.bind(this);
        this._renderUncontrolledIFrame = this._renderUncontrolledIFrame.bind(
            this,
        );
        this._renderControlledIFrame = this._renderControlledIFrame.bind(this);
        this._addCSSClasses = this._addCSSClasses.bind(this);
    }

    componentDidMount() {
        // Store the cross domain iframes in a global var array to keep track of multiple domains.
        var globalDIObject = window.DI || {};
        globalDIObject.checkOriginDomains =
            globalDIObject.checkOriginDomains || [];

        // Store the current window location as an origin to allow resize requests from.
        if (globalDIObject.checkOriginDomains.length == 0) {
            globalDIObject.checkOriginDomains.push(window.location.origin);
        }
        // Store the iframe src domain as an origin to allow resize requests from.
        if (typeof this.props.src != 'undefined') {
            var location = this._getLocation(this.props.src);
            globalDIObject.checkOriginDomains.push(location.host);
        }

        // Add all domains passed in the allowDomains prop so the resizer can communicate back to the parent.
        if (this.props.allowDomains && this.props.allowDomains.length > 0) {
            this.props.allowDomains.forEach((domain) => {
                // Prefix the domain with HTTPS if the domain doesn't already contain it.
                if (
                    domain &&
                    domain.indexOf('http') == -1 &&
                    domain.indexOf('https') == -1
                ) {
                    domain = 'https://' + domain;
                }
                globalDIObject.checkOriginDomains.push(domain);
            });
        }

        // Allow scrolling by default unless explicitly set to false;
        var enableScrolling = this.props.scrolling == false ? false : true;

        let resizerOptions = {
            log: false, // Enable console logging
            scrolling: enableScrolling,
            inPageLinks: true,
            heightCalculationMethod: 'lowestElement',
            checkOrigin: globalDIObject.checkOriginDomains,
        };

        // Set the configured resizer options if provided.
        if (this.props.resizerOptions) {
            Object.assign(resizerOptions, this.props.resizerOptions);
        }

        // Initialize iframeResize after the component has mounted with the iframes.
        // This object should be provided by the SPA.
        if (typeof iFrameResize !== 'undefined') {
            iFrameResize(resizerOptions, '.resizeIframe');
        }

        if (this.props.src == undefined) {
            this._renderFrameContents();
        } else {
            this._setMinUncontrolledFrameHeight();
        }
    }

    componentDidUpdate() {
        if (this.props.src == undefined) {
            this._renderFrameContents();
        }
    }

    componentWillUnmount() {
        if (this.props.src == undefined) {
            unmountComponentAtNode(
                this.controlledRef.current.contentDocument.body,
            );
        }
    }

    render() {
        if (this.props.src == undefined) {
            return this._renderControlledIFrame();
        } else {
            return this._renderUncontrolledIFrame();
        }
    }

    _renderUncontrolledIFrame() {
        // Set the default height on the iframe since it's not managed by the resizer.
        var height = DEFAULT_UNCONTROLLED_IFRAME_HEIGHT;
        if (this.props.height != null && this.props.height > 0) {
            height = this.props.height;
        }

        var iFrameId = this.props.id || '';
        return (
            <div className={this._addCSSClasses()}>
                <iframe
                    ref={this.uncontrolledRef}
                    onLoad={this.props.onLoad}
                    title={this.props.title}
                    aria-describedby={iFrameId}
                    src={this.props.src}
                    id={iFrameId}
                    width="100%"
                    height={height}
                    className="resizeIframe"
                    frameBorder="0"
                />
            </div>
        );
    }

    _renderControlledIFrame() {
        // Set a height on the iframe since it's not managed by the resizer.
        var height = DEFAULT_UNCONTROLLED_IFRAME_HEIGHT;
        if (this.props.height != null && this.props.height > 0) {
            height = this.props.height;
        }

        var iFrameId = this.props.id || '';
        return (
            <div className={this._addCSSClasses()}>
                <iframe
                    ref={this.controlledRef}
                    onLoad={this.props.onLoad}
                    title={this.props.title}
                    aria-describedby={iFrameId}
                    className="resizeIframe"
                    id={iFrameId}
                    height={height}
                    width="100%"
                    frameBorder="0"
                />
            </div>
        );
    }

    /**
     * Adds CSS styles to the iframe. Also adds CSS needed to properly handle iframe overflow
     * in iOS. This CSS class is only added if an iOS user agent is detected. We
     * don't care about user agent spoofing for this, since that's not a
     * high traffic use case.
     */
    _addCSSClasses() {
        let className = '';
        if (this.props.className) {
            className += this.props.className + ' ';
        }

        let iOS =
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (iOS) {
            className += styles['iframe-overflow-wrapper'];
        }
        const { dbk } = window;
        const { isWebview } = dbk || {};
        if (isWebview && isWebview()) {
            console.log('isWebview');
            className += ` ${styles['iframe-webview-wrapper']}`;
        }

        console.log({ className });

        return className;
    }

    /**
     * Retrieves the specified src attribute as a location object.
     */
    _getLocation(href) {
        var location = document.createElement('a');
        location.href = href;
        // IE doesn't populate all link properties when setting .href with a relative URL,
        // however .href will return an absolute URL which then can be used on itself
        // to populate these additional fields.
        if (location.host == '') {
            location.href = location.href;
        }
        return location;
    }

    _renderFrameContents() {
        // // Get the content document of the frame to attach things to.
        var doc = this.controlledRef.current.contentDocument;
        var win = this.controlledRef.current.contentWindow;

        if (doc.readyState === 'complete') {
            win.showModal = window.showModal;
            win.redirect = window.redirect;
            FastClick.attach(doc.body);

            // Insert any data into the iframe window that was passed in as props.
            win.data = this.props.data;

            // Append the root iframe element to the body of the iframe.
            var iframeRoot = doc.getElementById('iframeRoot');
            if (iframeRoot == null) {
                iframeRoot = doc.createElement('div');
                iframeRoot.id = 'iframeRoot';
                var attr = doc.createAttribute('data-iframe-height');
                attr.value = true;
                iframeRoot.setAttributeNode(attr);

                doc.body.appendChild(iframeRoot);

                // Append any specified children into the iframe root.
                if (this.props.children) {
                    render(this.props.children, iframeRoot);
                }

                // Inject the JS and CSS dependencies into the iframe.
                this._injectCSS(doc.head);
                this._injectJS(doc.body);
            }
        } else {
            setTimeout(this._renderFrameContents, 0);
        }
    }

    _injectJS(body) {
        var jsIncludes = this.props.jsIncludes || [];

        var resizer = this.props.resizer;
        var labJS = this.props.labJS;

        // Use LABjs to assist with ordered asynchronous script loading. When injecting script elements into a doc
        // they are immediately executed on load which may result in errors unless ordered correctly.
        var LAB = document.createElement('script');
        LAB.type = 'text/javascript';
        LAB.src = labJS;
        LAB.async = true;
        // Create an inline script to load all required JS files with LABjs.
        LAB.onload = LAB.onreadystatechange = function () {
            var script = document.createElement('script');
            script.type = 'text/javascript';

            var injection = '$LAB';
            jsIncludes.forEach(function (src) {
                injection += '.script("' + src + '")';
            });

            if (resizer) {
                injection += '.script("' + resizer + '")';
            }

            injection += '.wait();';
            script.innerHTML = injection;
            body.appendChild(script);
        };
        body.appendChild(LAB);
    }

    _injectCSS(head) {
        var cssIncludes = this.props.cssIncludes || [];
        cssIncludes.forEach((href) => {
            head.appendChild(this._createLinkElement(href));
        });
    }

    _createLinkElement(href) {
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;
        return link;
    }

    _setMinUncontrolledFrameHeight() {
        const modernEnrollmentOption = sessionStorage.getItem(
            'modernEnrollmentOption',
        );

        // resize frame only if not in homepage
        const isHomePage =
            window.location.href.endsWith('/app/home') ||
            window.location.href.endsWith('/app/home/') ||
            window.location.href.indexOf('/olb/accounts') !== -1;

        let footer = document.getElementById('footer-container');
        let header = document.getElementsByClassName('header-container')[0];
        let nav = document.getElementsByClassName(
            'main-navigation-container',
        )[0];
        if (
            this.uncontrolledRef &&
            this.uncontrolledRef.current &&
            footer &&
            header &&
            nav &&
            (modernEnrollmentOption === 'AUTO_ENROLL' || !isHomePage)
        ) {
            this.uncontrolledRef.current.style['min-height'] =
                'calc(100vh - ' +
                footer.offsetHeight +
                'px - ' +
                header.offsetHeight +
                'px - ' +
                nav.offsetHeight +
                'px)';
        }
    }
}

IFrame.propTypes = {
    /**
     * Array of css files to include.
     */
    cssIncludes: PropTypes.arrayOf(PropTypes.string),
    /**
     * Array of js files to include.
     */
    jsIncludes: PropTypes.arrayOf(PropTypes.string),
    /**
     * The URL for an iFrame resizer.
     */
    resizer: PropTypes.string.isRequired,
    /**
     * Options to modify the iFrame resizer.
     */
    resizerOptions: PropTypes.object,
    /**
     * String for LAB.js URL.
     */
    labJS: PropTypes.string.isRequired,
    /**
     * Data to add to the iFrame window.
     */
    data: PropTypes.object,
    /**
     * String to add classname to the div containing the iFrame for styling purposes.
     */
    className: PropTypes.string,
    /**
     * Defaults to true, if false will prevent scrolling in iFrames.
     */
    scrolling: PropTypes.bool,
    /**
     * String array of allowable domains for the iFrame to use.
     */
    allowDomains: PropTypes.arrayOf(PropTypes.string),
    /**
     * Custom function to be executed when the iFrame loads.
     */
    onLoad: PropTypes.func,
    /**
     * String to add title attribute to the iFrame.
     */
    title: PropTypes.string,
};
