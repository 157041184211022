import {
    JourneyType,
    getADRequestHeaders,
    getActiveJourneyType,
} from 'common-ui/src/anomaly-detection';
import Fetch from '../../USPFetch';
import Constants from '../../common/constants/Constants';

const convertResponseToJSON = (response) => {
    const { data } = response;
    const { result } = data || {};
    try {
        response.data = JSON.parse(result);
    } catch (e) {
        response.data = result;
    }

    return response;
};

const addRegionToStepUpURL = (url) => {
    let newUrl = url;
    let context = '';

    if (window.parent.location.pathname.indexOf('/live') !== -1) {
        context = 'auth-olb/live';
    } else if (window.parent.location.pathname.indexOf('/beta') !== -1) {
        context = 'auth-olb/beta';
    }

    if (context !== '') {
        newUrl = url.replace('auth-olb', context);
    }

    return newUrl;
};
const getAnomalyDetectionIdfromQuery = () => {
    // SSO will sent the journeyId as a query string and to propogate journeyId to "/step-up-authentication/submit" retrieving from session storage

    if (
        getActiveJourneyType() &&
        Object.values(JourneyType).includes(getActiveJourneyType())
    ) {
        return getADRequestHeaders();
    }

    let { adJourneyId, adSessionId } = getADRequestHeaders();

    adSessionId = sessionStorage.getItem(Constants.SESSION_ID) ?? adSessionId;
    adJourneyId =
        adJourneyId ?? sessionStorage.getItem(Constants.AD_JOURNEY_ID);

    if (adSessionId && adJourneyId) {
        return { adJourneyId, adSessionId };
    }

    return {};
};
export default class StepUpAuthenticationService {
    static sendStepUpAuthentication(
        destinationProtocol,
        otp,
        CSRFToken,
        forAppCode,
        featureCode,
        isRepeatableChallenge,
        reason,
    ) {
        const stepUpAuthenticationData = {
            type: 'OTP',
            displayMethod: destinationProtocol,
            otp: otp || '',
            CSRFToken: CSRFToken || '',
            forAppCode: forAppCode || '',
            feature: featureCode || '',
            repeatableChallenge: isRepeatableChallenge,
            reason: reason || '',
        };

        return Fetch.postFormData(
            addRegionToStepUpURL(Constants.STEP_UP_AUTH_URL),
            stepUpAuthenticationData,
            getAnomalyDetectionIdfromQuery(),
        ).then(convertResponseToJSON);
    }

    static sendStepUpAuthenticationPhone(
        destinationProtocol,
        destinationCountryCode,
        destinationPhoneNumber,
        CSRFToken,
        forAppCode,
        featureCode,
        isRepeatableChallenge,
        reason,
    ) {
        const stepUpAuthenticationPhoneData = {
            displayMethod: destinationProtocol,
            destinationCountryCode: destinationCountryCode || '',
            destinationPhoneNumber: destinationPhoneNumber || '',
            CSRFToken: CSRFToken || '',
            forAppCode: forAppCode || '',
            feature: featureCode || '',
            repeatableChallenge: isRepeatableChallenge,
            reason: reason || '',
        };

        return Fetch.postFormData(
            addRegionToStepUpURL(Constants.STEP_UP_AUTH_URL),
            stepUpAuthenticationPhoneData,
            getAnomalyDetectionIdfromQuery(),
        ).then(convertResponseToJSON);
    }

    static sendStepUpAuthenticationEmail(
        destinationEmail,
        CSRFToken,
        forAppCode,
        featureCode,
        isRepeatableChallenge,
        reason,
    ) {
        const stepUpAuthenticationEmailData = {
            displayMethod: 'EMAIL',
            destinationEmail: destinationEmail || '',
            CSRFToken: CSRFToken || '',
            forAppCode: forAppCode || '',
            feature: featureCode || '',
            repeatableChallenge: isRepeatableChallenge,
            reason: reason || '',
        };

        return Fetch.postFormData(
            addRegionToStepUpURL(Constants.STEP_UP_AUTH_URL),
            stepUpAuthenticationEmailData,
            getAnomalyDetectionIdfromQuery(),
        ).then(convertResponseToJSON);
    }
}
